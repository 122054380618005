.bikes {
  padding-top: 200px;
  position: relative;
}

.bikes_content {
  z-index: 1;
  position: relative;
}

.products {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 32px;
  margin-bottom: 64px;

  &.noProducts {
    display: block;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 48px;
}

.search {
  background-color: #fff;
  padding: 5px;
  border-radius: 5px;
  font-weight: 500;
  display: flex;
  align-items: center;

  svg {
    margin: 0 10px;
    color: #222;
  }

  input {
    border: none;
    padding: 8px;
    background: transparent;
    width: 300px;

    &:focus {
      outline: none;
    }
  }

  &:focus-within {
    background-color: #ddd;
  }
}

.header_left {
  display: flex;
  align-items: center;
}

.selector {
  display: flex;
  margin-left: 128px;

  button {
    background: transparent;
    border: none;
    color: #fff;
    font-size: 16px;
    margin: 0 12px;
    position: relative;
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
      transform: translateY(2px);
    }

    &.active {
      color: #da2121;

      &:after {
        width: 100%;
        position: absolute;
        display: block;
        height: 2px;
        background-color: #da2121;
        bottom: -4px;
        left: 0;
        content: "";
      }
    }
  }
}

.notFound {
  margin: 0 auto;
  width: 100%;
  text-align: center;
  padding: 100px 0;

  svg {
    color: #da2121;
    font-size: 64px;
    margin-bottom: 12px;
  }

  p {
    color: #fff;
    font-size: 24px;
  }
}

@media screen and (max-width: 1064px) {
  .products {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .bikes {
    padding-top: 150px;
  }

  .header {
    display: block;
  }

  .header_left {
    margin-bottom: 24px;
    justify-content: space-between;
  }

  .selector {
    margin-left: 0;
  }
}

@media screen and (max-width: 780px) {
  .products {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 28px;
  }
}

@media screen and (max-width: 620px) {
  .header_left {
    display: block;
  }

  .selector {
    background-color: rgba(#fff, 0.2);
    margin-top: 10px;
    padding: 10px 10px 14px;
    border-radius: 10px;
  }

  .products {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 24px;
  }
}

.bike {
  padding: 200px 0;
}

.bike_content {
  position: relative;
  z-index: 1;
}

.product {
  display: flex;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-top: 64px;
}

.slide {
}

.slide_img {
  height: 800px;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.thumbnails {
  width: 100%;
  padding: 10px;
  background-color: #fff;
  margin-top: 8px;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 8px;
}

.thumbnail {
  border: none;
  cursor: pointer;

  &:hover img {
    filter: brightness(80%);
  }

  &.active img {
    border: 2px solid #da2121;
  }

  img {
    border: 2px solid #fff;
    width: 80px;
    height: 80px;
    border-radius: 5px;
    display: block;
    object-fit: cover;
    transition: all 0.2s;
  }
}

.notFound {
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
  padding: 100px 0;

  svg {
    color: #da2121;
    font-size: 128px;
  }

  p {
    color: #fff;
    font-size: 24px;
  }
}

.slider {
  width: 50%;
}

.slider_mock,
.data_mock {
  height: 400px;
  width: 100%;
  background-color: #f0f0f0;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}

.slider_mock {
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    font-size: 48px;
    color: rgba(#000, 0.2);
  }
}

.data_mock_text,
.data_mock_price,
.data_mock_title {
  background-color: rgba(#000, 0.1);
}

.data_mock {
  padding: 60px;
}

.data_mock_title {
  height: 40px;
  width: 70%;
  margin-bottom: 40px;
}

.data_mock_text {
  height: 20px;
  margin: 10px 0;
}

.data_mock_price {
  height: 30px;
  width: 30%;
  margin-top: 40px;
}

.data {
  color: #222;
  background-color: #fff;
  border-radius: 10px;
  padding: 40px;
  width: 100%;
}

.category {
  color: #666;
  font-weight: normal;
  font-size: 16px;
  margin-bottom: 24px;
}

.metadata_label {
  color: #666;
}

.metadata {
  li {
    list-style: none;
    display: flex;
    align-items: center;

    b {
      margin-left: 4px;
    }

    svg {
      color: #aaa;
      margin-right: 4px;
    }
  }
}

.price {
  color: #da2121;
  font-weight: bold;
  font-size: 32px;
  margin: 20px 0;
}

.ctas {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;

  button {
    padding: 10px 0;
  }
}

.whatsApp {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;

  svg {
    margin-right: 4px;
  }
}

.description {
  margin-top: 48px;
}

@media screen and (max-width: 1024px) {
  .product {
    display: block;
    margin-top: 18px;
  }

  .bike {
    padding-top: 150px;
  }

  .slider,
  .data {
    width: 100%;
  }

  .thumbnails {
    margin-top: 0;
  }

  .data {
    margin-top: 12px;
  }
}

.header {
  width: 100%;
  position: fixed;
  height: 112px;
  z-index: 20;
  transition: all 0.2s;

  &.scrolled {
    background-color: #222;
    height: 70px;

    & .logo {
      height: 36px;
    }

    & .nav ul li a {
      font-size: 16px;
      font-weight: 600;
    }
  }
}

.wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  position: relative;
}

.logo {
  height: 40px;
  transition: all 0.2s;
}

.nav {
  ul {
    display: flex;

    li {
      list-style: none;
      font-weight: bold;
      margin-left: 32px;

      a {
        color: #fff;
        text-decoration: none;
        font-size: 18px;
        position: relative;
        transition: all 0.2s;

        &.active {
          color: #da2121;

          &:after {
            transform: scaleX(1);
          }
        }

        &:hover:after {
          transform: scaleX(1);
        }

        &:after {
          width: 80%;
          height: 2px;
          background-color: #da2121;
          position: absolute;
          left: 10%;
          right: 0;
          bottom: -5px;
          content: "";
          transform-origin: 50% 50%;
          transform: scaleX(0);
          transition: all 0.2s;
        }
      }
    }
  }
}

.pancake {
  align-items: center;
  border: none;
  background: transparent;
  cursor: pointer;
  display: none;
}

.pancakeButton {
  width: 36px;
  height: 32px;
  justify-content: center;
  display: flex;
  flex-direction: column;

  span {
    height: 4px;
    width: 100%;
    background-color: #fff;
    border-radius: 5px;
    display: block;
    margin: 3px 0;
    transition: all 0.2s;
  }
}

.pancake.active {
  .pancakeButton span {
    background-color: #da2121;
  }

  .pancakeLabel {
    color: #da2121;
  }

  .pancakeButton span:nth-child(1) {
    transform: translate3d(0, 10px, 0) rotate(-45deg);
  }

  .pancakeButton span:nth-child(3) {
    transform: translate3d(0, -10px, 0) rotate(45deg);
  }

  .pancakeButton span:nth-child(2) {
    opacity: 0;
    transform: scaleX(0);
  }
}

.pancakeLabel {
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  display: block;
  margin-left: 10px;
  transition: all 0.2s;
}

@media screen and (max-width: 1200px) {
  .nav ul li a {
    font-size: 16px;
    font-weight: 500;
  }
}

@media screen and (max-width: 1024px) {
  .pancake {
    display: flex;
  }

  .nav {
    opacity: 0;
    visibility: hidden;
    transform: translate3d(0, 80px, 0);
    position: absolute;
    background-color: #222;
    border-radius: 10px;
    top: calc(100% + 24px);
    width: 100%;
    left: 0;
    padding: 20px;
    transition: all 0.2s;

    ul {
      display: block;

      li {
        margin-left: 0;
        text-align: center;
        margin: 20px 0;
      }
    }
  }

  .logo {
    height: 28px;
  }

  .header {
    &.scrolled {
      .logo {
        height: 28px;
      }
    }
  }

  .nav.active {
    opacity: 1;
    visibility: visible;
    transform: translate3d(0, 0, 0);
  }
}

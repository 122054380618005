.contact_content {
  position: relative;
  z-index: 1;
  padding: 200px 0 140px;
}

.contact {
  background-color: #444;
}

.contact_content {
  display: grid;
  gap: 48px;
  align-items: center;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
}

.form_block {
  label {
    color: #fff;
    font-weight: 500;
    display: block;
    margin-bottom: 4px;
    font-size: 14px;
  }

  input,
  textarea {
    width: 100%;
    border: none;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 18px;
  }

  textarea {
    height: 100px;
  }
}

.submit {
  padding: 10px 32px;
  font-weight: 500;
  font-size: 16px;

  &[disabled] {
    opacity: 0.4;
    pointer-events: none;
  }
}

.map {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 100%;
  display: block;
}

.data {
  background-color: #fff;
  padding: 24px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.list {
  li {
    display: flex;
    align-items: flex-start;
    margin-bottom: 12px;
    font-weight: 500;
    color: #222;
    flex-wrap: wrap;
  }

  .list_sp {
    align-self: flex-end;
    display: block;
    width: 100%;
    margin-left: 32px;
  }

  svg {
    color: #888;
    flex-basis: 32px;
    flex-grow: 0;
    flex-shrink: 0;
    text-align: center;
    display: block;
    transform: translateY(2px);
  }
}

.socials {
  margin-top: 24px;
  a {
    color: #da2121;
    font-size: 24px;
    margin: 0 6px;
  }
}

.subtitle {
  color: #fff;
  margin-top: 10px;
  margin-bottom: 40px;
}

@media screen and (max-width: 980px) {
  .contact_content {
    display: grid;
    grid-template-columns: 1fr;
  }
}

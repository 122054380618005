// 0A0C19
// da2121

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Barlow, sans-serif;
}

.wrapper {
  width: 90%;
  max-width: 1360px;
  margin: 0 auto;
}

.section {
  padding: 80px 0;
}

.section_subtitle {
  font-size: 42px;
  margin-bottom: 20px;

  &.light {
    color: #fff;
  }
}

.section_text {
  line-height: 1.5;
  font-size: 18px;

  & + .section_text {
    margin-top: 16px;
  }

  &.light {
    color: #fff;
  }
}

.button {
  background-color: #da2121;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: darken(#da2121, 10%);
  }

  &.secondary {
    background-color: #fdd8d8;
    color: #da2121;

    &:hover {
      background-color: darken(#fdd8d8, 10%);
    }
  }

  &.bold {
    font-weight: bold;
  }
}

.link {
  display: inline-flex;
  align-items: center;
  color: #da2121;
  text-decoration: none;
  font-weight: bold;
  transform: translateY(2px);

  svg {
    margin-right: 2px;
  }
}

button.link {
  cursor: pointer;
  background: transparent;
  border: none;
  font-weight: 600;
  font-size: 15px;

  &:hover {
    color: #fff;
  }

  &[disabled] {
    pointer-events: none;
    opacity: 0.4;
  }
}

.button_link {
  background-color: #da2121;
  color: #fff;
  text-decoration: none;
  padding: 12px;
  display: inline-block;
  border-radius: 10px;
  cursor: pointer;
  font-weight: 500;

  &:hover {
    background-color: darken(#da2121, 10%);
  }
}

.page_title {
  color: #fff;
  display: flex;
  align-items: center;
}

.page_title_icon {
  color: #da2121;
  margin-right: 12px;
}

.skeleton {
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      transparent,
      rgba(#fff, 0.2),
      transparent
    );
    animation: sweep 1.5s infinite;
  }

  &.dark::after {
    background: linear-gradient(
      90deg,
      transparent,
      rgba(#000, 0.2),
      transparent
    );
  }
}

@keyframes sweep {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.slick-next {
  right: 10px;
}

.slick-prev {
  left: 10px;
  z-index: 1;
}

.bike_slider .slick-list {
  border-radius: 15px;
}

html,
body,
#root {
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1 0 auto;
  background-color: #444;
}

.floating_wsp {
  position: fixed;
  bottom: 80px;
  right: 80px;
  height: 60px;
  width: 60px;
  border-radius: 100%;
  background-color: #25d366;
  z-index: 200;
  color: #fff;
  font-size: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 5px 10px 0 rgba(#000, 0.4);
}

@media screen and (max-width: 600px) {
  .section {
    padding: 48px 0;
  }

  .section_subtitle {
    font-size: 32px;
  }

  .section_text {
    line-height: 1.4;
    font-size: 16px;
    font-weight: 500;
  }

  .floating_wsp {
    height: 48px;
    width: 48px;
    font-size: 22px;
    right: 24px;
    bottom: 24px;
  }
}

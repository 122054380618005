.jobs_content {
  position: relative;
  z-index: 2;
  padding-top: 200px;
}

.jobs {
  background-color: #444;
}

.grid {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 10px;
  margin: 48px 0;
}

.item {
  aspect-ratio: 1 / 1;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.item_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.item:hover {
  .item_name {
    transform: translate3d(0, 0, 0);
  }
}

.item_name {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  padding: 18px;
  text-align: left;
  background: linear-gradient(to top, #000, transparent);
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  transition: all 0.2s;
  transform: translate3d(0, 100%, 0);
}

.job {
  display: flex;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-top: 24px;
  margin-bottom: 96px;
}

.slide_vid_container {
  display: flex;
  background-color: #000;
  justify-content: center;
}

.slide {
  height: 800px;
  overflow: hidden;
}

.slide_vid {
  height: 100%;
}

.job_slider {
  width: 50%;
}

.slide_img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.thumbnails {
  width: 100%;
  padding: 10px;
  background-color: #fff;
  margin-top: 8px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  gap: 8px;
}

.thumbnail {
  border: none;
  cursor: pointer;

  &:hover img {
    filter: brightness(80%);
  }

  &.active img {
    border: 2px solid #da2121;
  }
  &.active .thumbnail_vid {
    border: 2px solid #da2121;
  }

  .thumbnail_vid {
    border: 2px solid #fff;
    width: 80px;
    height: 80px;
    border-radius: 5px;
    display: block;
    object-fit: cover;
  }

  img {
    border: 2px solid #fff;
    width: 80px;
    height: 80px;
    border-radius: 5px;
    display: block;
    object-fit: cover;
    transition: all 0.2s;
  }
}

.job_data {
  background-color: #fff;
  color: #222;
  width: 50%;
  border-radius: 10px;
  padding: 32px;

  h3 {
    font-size: 32px;
    margin-bottom: 48px;
  }
}

.go_back {
  background: transparent;
  border: none;
  margin-top: 32px;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
}

.skeleton {
  background-color: #fff;
  aspect-ratio: 1 / 1;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

@media screen and (max-width: 1024px) {
  .jobs_content {
    padding-top: 150px;
  }
}

@media screen and (max-width: 920px) {
  .grid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .job {
    display: block;
  }

  .job_slider {
    width: 100%;
  }

  .thumbnails {
    margin-top: 0;
  }

  .job_data {
    width: 100%;
    margin-top: 12px;
  }
}

@media screen and (max-width: 720px) {
  .grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media screen and (max-width: 500px) {
  .grid {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

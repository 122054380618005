.gradient {
  position: absolute;
  height: 100%;
  background-color: #444;
  width: 100%;
  top: 0;
  z-index: 0;
}

.bg {
  height: 100vh;
  background-size: cover;
  background-position: center;
  position: relative;

  &:after {
    height: 300px;
    background: linear-gradient(to top, #444, transparent);
    left: 0;
    bottom: 0px;
    width: 100%;
    content: "";
    display: block;
    position: absolute;
  }
}

.hero {
  height: 800px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  align-items: center;
  display: flex;
}

.wrap {
  text-align: center;
}

.upperTitle {
  color: #da2121;
  font-weight: normal;
}

.title {
  color: #fff;
  font-size: 46px;
}

.heroText {
  color: #da2121;
}

.subtitle {
  color: #fff;
  font-size: 18px;
  max-width: 600px;
  margin: 0 auto;

  &.first {
    margin-top: 48px;
  }
}

.heroLink {
  background-color: #da2121;
  color: #fff;
  text-decoration: none;
  padding: 12px 20px;
  display: inline-block;
  font-weight: bold;
  margin-top: 64px;
  transition: all 0.2s;
  border-radius: 10px;

  &:hover {
    transform: scale(1.02);
    background-color: darken(#da2121, 10%);
  }
}

.dynamic_text_mobile {
  display: none;
}

@media screen and (max-width: 1024px) {
  .title {
    font-size: 64px;
  }
}

@media screen and (max-width: 868px) {
  .title {
    font-size: 32px;
  }

  .upperTitle {
    font-size: 18px;
  }

  .subtitle {
    font-size: 16px;
  }

  .heroLink {
    font-size: 14px;
    padding: 8px 16px;
    font-weight: 500;
  }

  .hero {
    padding: 0;
    height: 780px;
    display: flex;
    align-items: center;
  }
}

@media screen and (max-width: 500px) {
  .dynamic_text_mobile {
    display: block;
  }

  .dynamic_text_pc {
    display: none;
  }

  .hero {
    height: 680px;
    line-height: 1.3;
  }

  .title_fixed {
    display: block;
  }

  .title {
    margin-top: 24px;
  }
}

@media screen and (max-width: 380px) {
  .title {
    line-height: 1.2;
  }
}

.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #111;
}

.form {
  background-color: #222;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
}

.logo {
  display: block;
  margin: 0 auto 2rem;
  max-width: 100%;
  height: auto;
}

.loginButton {
  margin-top: 1rem !important;
}

@media (max-width: 600px) {
  .form {
    padding: 1.5rem;
  }
}

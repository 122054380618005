.us_content {
  position: relative;
  z-index: 2;
  padding-top: 200px;
}

.us {
  background-color: #444;
}

.us_blocks {
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  gap: 48px;
  margin: 24px 0 48px;
  align-items: center;
}

.us_block {
  p {
    color: #fff;
    line-height: 1.5;

    & + p {
      margin-top: 24px;
    }
  }
}

.us_img {
  width: 100%;
  border-radius: 10px;
}

@media screen and (max-width: 1024px) {
  .us_blocks {
    display: flex;
    flex-direction: column-reverse;
  }

  .block_text {
    margin-bottom: 48px;
  }

  .us_content {
    padding-top: 150px;
  }
}

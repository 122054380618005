.sidebar {
  background-color: #222;
}

.user {
  text-align: center;
  padding: 48px 32px;
}

.avatar {
  background-color: #da2121;
  width: 64px;
  height: 64px;
  color: rgba(#fff, 0.6);
  border-radius: 100%;
  font-size: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.user_name {
  color: #fff;
  font-size: 16px;
  margin-top: 12px;
}

.link {
  color: #fff;
  text-decoration: none;
  display: flex;
  padding: 12px 24px;
  font-weight: 500;
  align-items: center;

  svg {
    color: rgba(#fff, 0.4);
    margin-right: 8px;
  }

  &.active {
    color: #da2121;

    svg {
      color: rgba(#da2121, 0.5);
    }
  }
}

@media screen and (max-width: 1064px) {
  .sidebar {
    position: fixed;
    z-index: 200;
    min-height: 100vh;
    overflow-y: auto;
    width: 320px;
    transform: translate3d(-100%, 0, 0);
    transition: all 0.2s;

    &.open {
      transform: translate3d(0, 0, 0);
    }
  }
}

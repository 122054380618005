.services_content {
  position: relative;
  z-index: 2;
  padding-top: 200px;
}

.services {
  background-color: #444;
}

.texts {
  margin-top: 48px;
  p {
    color: #fff;
    line-height: 1.5;

    & + p {
      margin-top: 24px;
    }
  }
}

.cards {
  margin: 48px 0 96px;
  display: flex;
  gap: 24px;
}

.card {
  color: #222;
  background-color: #fff;
  flex-basis: 25%;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(#000, 0.4);
  transition: all 0.2s;

  &:hover {
    transform: translate3d(0, -10px, 0);
    box-shadow: 0 10px 20px 0 rgba(#000, 0.7);
  }

  svg {
    color: #da2121;
    font-size: 48px;
    margin-bottom: 12px;
  }

  h2 {
    margin-bottom: 12px;
  }

  p {
    line-height: 1.5;
    font-weight: 500;
  }
}

@media screen and (max-width: 1000px) {
  .cards {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .services_content {
    padding-top: 150px;
  }
}

@media screen and (max-width: 450px) {
  .cards {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

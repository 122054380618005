.admin {
  display: grid;
  grid-template-columns: 320px 1fr;
  background-color: #333;
  min-height: 100vh;
}

.admin_content {
  padding: 32px;
  background-color: #333;
}

.admin_header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h1 {
    font-size: 24px;
    color: #fff;
    font-weight: 500;
    display: flex;
    align-items: center;

    svg {
      color: rgba(#fff, 0.4);
      margin-right: 10px;
    }
  }
}

.admin_hamburguer_wrap {
  position: fixed;
  z-index: 300;
  display: none;

  &::after {
    background-color: #000;
    width: 150px;
    height: 150px;
    border-radius: 100%;
    content: "";
    position: absolute;
    opacity: 0.7;
    left: -75px;
    top: -75px;
    z-index: 0;
    filter: blur(40px);
  }
}

.admin_hamburguer {
  width: 40px;
  height: 40px;
  top: 34px;
  left: 40px;
  font-size: 32px;
  background: transparent;
  color: #fff;
  border: none;
  cursor: pointer;
  z-index: 1;
  position: absolute;

  &.open {
    color: #da2121;
  }
}

@media screen and (max-width: 1064px) {
  .admin_hamburguer_wrap {
    display: block;
  }

  .admin {
    grid-template-columns: 1fr;
  }

  .admin_header h1 {
    padding-left: 84px;
  }
}

.admin_data {
  margin-top: 24px;
  position: relative;
}

.admin_loading_overlay {
  background-color: rgba(#333, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  padding-top: 48px;
  z-index: 2;

  & .admin_loading_overlay_loader {
    color: #da2121;
    font-size: 48px;
    position: sticky;
    top: 400px;
    text-align: center;
    svg {
      animation: rotate 0.5s infinite linear;
    }
  }
}

.admin_loading {
  color: #da2121;
  font-size: 48px;
  padding-top: 200px;
  text-align: center;

  svg {
    animation: rotate 0.5s infinite linear;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.quill,
.ql-editor {
  background-color: #fff;
}

.ql-editor {
  color: #000;
}

.card {
  background-color: #fff;
  flex: 1;
  border-radius: 10px;
  box-shadow: 0 8px 14px 0 rgba(#000, 0.2);
  text-decoration: none;
  width: 100%;
  transition: all 0.2s;

  &:hover {
    transform: translate3d(0, -4px, 0);
  }
}

.img {
  width: 100%;
  display: block;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  object-fit: cover;
}

.data {
  padding: 14px;
}

.title {
  font-size: 18px;
  font-weight: 500;
  color: #000;
}

.separator {
  color: #aaa;
  transform: scaleY(0.8);
  display: inline-block;
  margin: 0 4px;
}

.metadata {
  color: #666;
}

.price {
  color: #da2121;
  font-size: 24px;
  font-weight: bold;
  margin-top: 24px;
}

.skeletonCard {
  background-color: #f0f0f0;
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.skeletonImage {
  width: 100%;
  height: 200px;
  background-color: #e0e0e0;
  position: relative;
  overflow: hidden;
}

.skeletonContent {
  padding: 16px;
}

.skeletonTitle {
  height: 24px;
  width: 80%;
  background-color: #e0e0e0;
  margin-bottom: 12px;
  position: relative;
  overflow: hidden;
}

.skeletonPrice {
  height: 16px;
  width: 40%;
  background-color: #e0e0e0;
  position: relative;
  overflow: hidden;
}

@media screen and (max-width: 1024px) {
}

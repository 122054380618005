.section {
  background-repeat: repeat;
}

.products {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 32px;
}

.see_more {
  margin-top: 48px;
}

@media screen and (max-width: 860px) {
  .products {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 28px;
  }
}

@media screen and (max-width: 500px) {
  .products {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 18px;
  }
}

.form {
  max-width: 580px;
  width: 100%;
}

.currency {
  display: flex;
  align-items: center;

  label {
    margin: 0 12px;
  }
}

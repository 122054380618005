.footer {
  background-color: #222;
  padding: 32px 0;
  z-index: 3;
  position: relative;
  flex-shrink: 0;
}

.wrapper {
  display: grid;
  align-items: center;
  grid-template-columns: 2fr minmax(0, 1fr) minmax(0, 1fr);
  gap: 32px;
}

.rights_reserved {
  color: #666;
  margin-top: 32px;
  display: block;
}

.logo {
  height: 120px;
}

.list {
  color: #fff;

  li {
    list-style: none;
  }

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      font-weight: bold;
    }
  }
}

.info {
  li {
    display: flex;
    align-items: flex-start;
    margin-bottom: 12px;
  }

  svg {
    color: #888;
    flex-basis: 32px;
    flex-grow: 0;
    flex-shrink: 0;
    text-align: center;
    display: block;
    transform: translateY(2px);
  }
}

.links li {
  margin: 8px 0;
}

.socials {
  margin-top: 24px;
  a {
    color: #fff;
    font-size: 24px;
    margin: 0 6px;
  }
}

@media screen and (max-width: 860px) {
  .wrapper {
    display: block;
    text-align: center;
  }

  .section {
    margin-bottom: 32px;
    padding-bottom: 32px;
    border-bottom: 1px solid rgba(#fff, 0.1);
  }

  .info li {
    justify-content: center;
  }

  .logo {
    height: unset;
    width: 100%;
    max-width: 200px;
  }
}

.products {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 24px;
}

.product {
  background-color: #fff;
  flex: 1;
  border-radius: 10px;
  box-shadow: 0 8px 14px 0 rgba(#000, 0.2);
  text-decoration: none;
  width: 100%;
  transition: all 0.2s;
  position: relative;
}

.deleteButton {
  position: absolute;
  background-color: #a82f2f;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #fff;
  border: none;
  cursor: pointer;
  border-bottom: 3px solid rgba(#000, 0.4);
  border-radius: 4px;
  width: 34px;
  height: 34px;
  top: 10px;
  right: 10px;
  transition: all 0.1s;

  &:hover {
    transform: scale(1.1);
  }
}

.img {
  width: 100%;
  display: block;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  object-fit: cover;
}

.data {
  padding: 14px;
}

.title {
  font-size: 18px;
  font-weight: 500;
  color: #000;
}

.separator {
  color: #aaa;
  transform: scaleY(0.8);
  display: inline-block;
  margin: 0 4px;
}

.metadata {
  color: #666;
}

.price {
  color: #da2121;
  font-size: 24px;
  font-weight: bold;
  margin-top: 24px;
}

@media screen and (max-width: 1280px) {
  .products {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media screen and (max-width: 820px) {
  .products {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media screen and (max-width: 540px) {
  .products {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}
